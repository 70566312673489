/* DashboardPage.css */
.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0; /* Removed the 20px padding */
}

.chatbot-container {
  margin-bottom: 0;
   /* Was 40px, now 0 */
}


.dashboard-buttons {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  justify-content: center;
}

.dashboard-button {
  width: 250px; /* Make the buttons bigger */
  height: 60px; /* Make the buttons taller */
  background: linear-gradient(145deg, #6c9b8b, #4a7d6a); /* Soft teal/blue gradient */
  color: white;
  font-size: 18px; /* Larger text */
  border: none;
  border-radius: 10px; /* Round corners */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  transition: all 0.3s ease; /* Smooth transition for all effects */
  font-weight: bold;
}

.dashboard-button:hover {
  background: linear-gradient(145deg, #7cb0a7, #5a906b); /* Slightly lighter shade on hover */
  transform: translateY(-5px); /* Lift button effect */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow on hover */
}

.dashboard-button:active {
  transform: translateY(2px); /* Button presses down when clicked */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); /* Shadow becomes more subtle when clicked */
}

.dashboard-button:focus {
  outline: none; /* Remove outline on focus */
  box-shadow: 0 0 0 3px rgba(150, 150, 150, 0.5); /* Focus outline effect */
}
.chat-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px;
  background-color: #fff;
  position: relative;
  width: 100%;
}

.chat-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 8px;
}

.attach-icon {
  background: none;
  border: none;
  font-size: 22px; /* Bigger & Bold */
  font-weight: bold;
  cursor: pointer;
  color: #007bff;
  margin-left: 10px;
}

.attach-icon:hover {
  color: #0056b3;
}
